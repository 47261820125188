<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
  
      <!-- Start Breadcrump Area  -->
      <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center rn-page-title pt--100">
                <h2 class="heading-title theme-gradient">
                  Grant Cycle B Proposals
                </h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->
  
      <!-- Start Portfolio Details Area  -->
      <div class="rn-portfolio-details ptb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="row row--35 mb--50">
                  <div class="col-lg-12">
                    <div class="inner">
                      <div class="section-title">
                        <h1 class="heading-title">
                            Exploring Gamification Strategies for Enhanced Learning in Higher Education
                        </h1>
                        <p class="description">
                          Grant:
                          <a
                            href="https://www.spencer.org/grant_types/small-research-grant"
                            target="_blank"
                            >Spencer Foundation Small Research Grant</a
                          >
                        </p>
                        <h4>Project Personnel</h4>
                        <p>Principal Investigator: Cody Squadroni<br>
  Co-Principal Investigator: TBD</p>
                        <h4>Proposal Summary</h4>
                        <p class="description">
                          Start Date: September 1, 2023<br />
                          End Date: August 31, 2024
                        </p>
                        <h5>Central Research Questions</h5>
                        <p>
                          <ol>
                              <li>What are the most effective gamification elements and mechanics for higher education
contexts?</li>
                              <li>How can these gamification strategies be customized to accommodate various
disciplines and course structures?</li>
                          </ol>
                        </p>
                        <h5>Project Summary</h5>
                        <p>
                            This proposal seeks to explore the potential of gamification strategies to enhance learning in
higher education, particularly in online and blended environments. The study will identify the
most effective gamification elements and mechanics, examine their customization potential for
various disciplines and course structures, and evaluate their impact on student motivation,
engagement, retention, and academic performance.</p>
<p>Gamification is the application of game design principles and mechanics to non-game contexts,
such as education. This approach is gaining popularity as a way to increase student
engagement and motivation and enhance learning outcomes. Proposed gamification ideas
include progression systems, badges, leaderboards, interactive quizzes, storytelling,
team-based challenges, and personalized learning paths.</p>
<p>A mixed-methods approach involving case studies, pilot courses, and controlled experiments
will be employed to gather data and insights from multiple higher education institutions and
diverse student populations. Data collection methods will include surveys, interviews,
observations, and academic performance records. The research findings will contribute to the
improvement of higher education by providing evidence-based recommendations for the
effective use of gamification strategies in various disciplines and course structures.
</p>
                        
  <h4>Budget and Budget Justification</h4>
  <h5>Salaries</h5>
  <p>PI: $6,000<br>Researcher: $14,000 <br> Graduate Student: $9,000</p>
  <h5>Benefits</h5>
  <p>PI Benefits: $1,800<br>Researcher Benefits: $4,800 <br>Graduate Student Benefits: $2,700<br>Tuition/Fees: $2,000</p>
  <h5>Travel</h5>
  <p>Project Travel: $3,000<br>Conference or Dissemination Travel: $1,000</p>
  <h5>Equipment and Software</h5>
  <p>Equipment: $1,300<br>Software: $700</p>
  <h5>Project Expenses</h5>
  <p>Supplies: $1,300<br>
  Participant Stipends/Costs: $1,000<br>
  Communication: $550<br>
  Transcription: $550</p>
  <h5>Other</h5>
  <p>Other Expenses: $300</p>
  <h6>Total Budget: $50,000</h6>
  <h5>Budget Justification</h5>
  <p>The proposed budget of $50,000 will cover the salaries of the PI, researcher, and graduate
student. The PI will oversee the entire project, including project design, data collection, analysis,
and dissemination, while the researcher will assist with project design, data collection, analysis,
and dissemination. The graduate student will assist with data collection, analysis, and
dissemination. The budget includes benefits for the PI, researcher, and graduate student, as
well as tuition and fees for one graduate student to assist with the project.</p>
<p>The travel expenses in the budget will cover travel for the PI, researcher, and graduate student
to attend project meetings, conduct site visits, and attend conferences related to the project.
The conference or dissemination travel expenses will cover travel for the PI, researcher, and
graduate student to present project findings at conferences and disseminate findings to
stakeholders.</p>
<p>The equipment expenses in the budget will cover the purchase of necessary equipment for data
collection, such as audio recording equipment and cameras. The software expenses in the
budget will cover the purchase of necessary software for data analysis, such as statistical
analysis software.</p>
<p>The project expenses in the budget will cover the purchase of necessary supplies for data
collection, such as notebooks and pens. The participant stipend expenses will cover
compensation for participants in the pilot courses and experiments. The communication
expenses will cover the cost of communication tools, such as video conferencing software, used
for project meetings and communication with participants. The transcription expenses will cover
the cost of transcribing recorded data.</p>
<p>Finally, the other expenses in the budget will cover any unforeseen expenses that may arise
during the project. The budget has been carefully crafted to cover all necessary expenses while
staying within the program's budget limitations.</p>

  <h4>Subcontracts</h4>
  <p>None</p>
  <h4>Proposal Narrative</h4>
  <h5>Introduction</h5>
  <p>This research proposal seeks to explore the effectiveness of gamification strategies in
enhancing learning outcomes in higher education. The project will investigate the most effective
gamification elements and mechanics and examine their customization potential for various
disciplines and course structures. The research findings will contribute to the improvement of
higher education by providing evidence-based recommendations for the effective use of
gamification strategies to enhance student motivation, engagement, retention, and academic
performance.</p>
  <h5>Rationale</h5>
  <p>The effectiveness of gamification strategies in enhancing learning outcomes has been
demonstrated in various studies. However, there is a need for further research to identify the
most effective gamification elements and mechanics and to examine their customization
potential for various disciplines and course structures. This study will fill this gap by exploring
the use of gamification strategies in higher education, particularly in online and blended
environments. The research will provide valuable insights into effectively using gamification
strategies to enhance learning outcomes in higher education.</p>
  <h5>Research Methods</h5>
  <p>he proposed research will employ a mixed-methods approach, including case studies, pilot
courses, and controlled experiments. The research methods will involve data collection through
surveys, interviews, observations, and academic performance records. The following sections
describe the proposed research methods in detail.</p>

<h6>Case Studies</h6>
<p>The study will involve multiple case studies of higher education institutions that
have implemented gamification strategies in their courses. The case studies will
provide valuable insights into the effectiveness of various gamification elements
and mechanics in different disciplines and course structures. The case studies
will involve data collection through interviews with faculty members and students,
observation of course activities, and analysis of academic performance records.</p>

<h6>Pilot Courses</h6>
<p>The research will also involve implementing pilot courses that incorporate
gamification strategies. The pilot courses will be designed to test the
effectiveness of specific gamification elements and mechanics in enhancing
student motivation, engagement, retention, and academic performance. The pilot
courses will involve diverse student populations from multiple higher education
institutions. The pilot courses will be conducted in online and blended
environments, which will allow for the examination of the effectiveness of
gamification strategies in these contexts. Data collection methods will include
surveys, interviews, observation of course activities, and analysis of academic
performance records.</p>
<h6>Controlled Experiments</h6>
<p>The study will also involve controlled experiments that examine the effectiveness
of specific gamification elements and mechanics in enhancing learning
outcomes. The controlled experiments will involve manipulating gamification
elements and mechanics, such as progression systems, badges, leaderboards,
and interactive quizzes, to test their impact on student motivation, engagement,
retention, and academic performance. The experiments will involve diverse
student populations from multiple higher education institutions. Data collection
methods will include surveys, interviews, observation of course activities, and
analysis of academic performance records.</p>

  <h5>Conclusion</h5>
  <p>In conclusion, this research proposal seeks to explore the potential of gamification strategies to
enhance learning outcomes in higher education. The study will identify the most effective
gamification elements and mechanics and examine their customization potential for various
disciplines and course structures. The research findings will contribute to improving higher
education by providing evidence-based recommendations for the effective use of gamification
strategies to enhance student motivation, engagement, retention, and academic performance.
</p>
  
                        <div class="portfolio-details-btn mt--30">
                          <router-link
                            class="btn-default btn-border"
                            to="/LDTE5870-3"
                            >Back to LDTE 5870</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../../components/header/HeaderThree";
  import Footer from "../../components/footer/FooterTwo";
  import feather from "feather-icons";
  
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
        ],
        socialList: [
          {
            url: "https://www.facebook.com/",
            icon: "facebook",
          },
          {
            url: "https://www.linkedin.com/",
            icon: "linkedin",
          },
          {
            url: "https://instagram.com/",
            icon: "instagram",
          },
          {
            url: "https://twitter.com/",
            icon: "twitter",
          },
        ],
        moreProjects: [
          {
            src: require("../../assets/images/portfolio/related-image-01.jpg"),
            title: "Digital Analysis",
            categorie: "Technique",
          },
          {
            src: require("../../assets/images/portfolio/related-image-02.jpg"),
            title: "Plan Management",
            categorie: "Planning",
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
  </script>
  